<template>
  <v-card class="pa-4" outlined tile>
    <v-card-title class="headline">武力排名</v-card-title>
    <v-card-subtitle>
      <div style="padding-top: 15px">
        数据来源：<a href="https://www.zhihu.com/question/521940059/answer/3264616239" target="_blank">武评统计活动</a>
      </div>
    </v-card-subtitle>
    <v-data-table :headers="headers" :items="list" :items-per-page="100" hide-default-footer no-data-text="正在加载数据"></v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  name: "Force",
  data() {
    return {
      list: [],
      headers: [
        { text: '姓名', value: 'name' },
        { text: '武力值', value: 'force_value' },
        { text: '标准差', value: 'force_standard_deviation' },
      ],
    }
  },
  created() {
    axios.get("https://api.qidafang.com/force-list")
      .then(response => {
        this.list = response.data
      })
      .catch(error => console.error(error));
  },
  methods: {
  }
};
</script>

<style scoped></style>
